<script>
import Layout from "../../../layouts/main.vue";
// import PageHeader from "@/components/page-header";
import useGlobalData from "@/state/global-data";

export default {
  page: {
    title: "Install",
  },
  methods: {
    copyCode: function() {
      navigator.clipboard.writeText(this.provisioningKey);
    }
  },
  data() {
    const {userType, usergroupType, provisioningKey} = useGlobalData();
    return {
      title: this.$t("t-menu-install"),
      items: [
      ],
      provisioningKey,
      usergroupType,
      userType,
    };
  },
  mounted() {
    if (sessionStorage.getItem('plugin')) {
      this.$refs.message_install.style.display = "none";
      this.$refs.message_installed.style.display = "block";
    } else {
      this.$refs.message_install.style.display = "block";
      this.$refs.message_installed.style.display = "none";
    }

  },
  components: {
    Layout,
    // PageHeader,
  },
};
</script>

<template>
  <Layout>
<!--    <PageHeader :title="title" :items="items" />-->
    <div class="row">
      <div class="col-12 text-center">
        <h4 ref="message_install" class="text-center">{{ $t("t-install-message-install") }}</h4>
        <h4 ref="message_installed" class="text-center">{{ $t("t-install-message-installed") }}</h4>
        <p class="text-muted mb-4 fs-15">
          {{ $t("t-install-deploy-steps") }}
        </p>
        <br>
      </div>
      <div class="col-xxl-4 col-lg-4">
        <div class="card card-body text-center">
          <div class="avatar-sm flex-shrink-0 m-auto mb-3"><span class="avatar-title bg-soft-info rounded-circle fs-2 text-primary ">
            1
          </span></div>
          <h4 class="card-title mb-2">{{ $t("t-install-step-1-title") }}</h4>
          <p class="card-text text-muted">{{ $t("t-install-step-1-subtitle") }}</p>
          <div class="input-group">
            <input type="text" class="form-control text-center" id="inlineFormInputGroupUsername" :placeholder="provisioningKey" disabled>
            <div class="input-group-append">
              <btn class="input-group-text btn btn-success" @click="copyCode()"><i class="ri-file-copy-line text-white"></i></btn>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-4 col-lg-4">
        <div class="card card-body text-center">
          <div class="avatar-sm flex-shrink-0 m-auto mb-3"><span class="avatar-title bg-soft-info rounded-circle fs-2 text-primary ">
            2
          </span></div>
          <h4 class="card-title mb-2">{{ $t("t-install-step-2-title") }}</h4>
          <p class="card-text text-muted">{{ $t("t-install-step-2-subtitle") }}</p>
          <a target="_blank" href="https://bg-distribution.s3.eu-central-1.amazonaws.com/installer/partner-install.ps1" class="btn btn-success">{{ $t("t-install-step-2-button") }}</a>
        </div>
      </div>
      <div class="col-xxl-4 col-lg-4">
        <div class="card card-body text-center">
          <div class="avatar-sm flex-shrink-0 m-auto mb-3"><span class="avatar-title bg-soft-info rounded-circle fs-2 text-primary ">
            3
          </span></div>
          <h4 class="card-title mb-2">{{ $t("t-install-step-3-title") }}</h4>
          <p class="card-text text-muted">{{ $t("t-install-step-3-subtitle") }}</p>
          <a target="_blank" href="https://support.bodyguard.io/article/23/partner-deployment-via-intune" class="btn btn-success">{{ $t("t-install-step-3-button") }}</a>
        </div>
      </div>
      <!-- end col -->
    </div>
    <br><br>
    <!-- end row -->
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="text-center">{{ $t("t-install-uninstall-title") }}</h4>
        <p class="text-muted mb-4 fs-15">
          <span v-html="$t('t-install-uninstall-instructions')"></span>
        </p>
      </div>
    </div>
  </Layout>
</template>